<template>
    <section v-if="getCheckoutPackage" class="checkout-wrapper" :class="{ 'ja' : $i18n.locale == 'ja' }">
        <cookies-popup />

        <div class="checkout-nav">
            <div class="left-side">
                <router-link class="logo" to="/"></router-link>
            </div>
        </div>

        <h2>{{ $t("checkout.title") }}</h2>
        <p class="checkout-sub-title">{{ $t("checkout.subtitle") }}</p>
        <div class="checkout-inner">
            <checkout-form />
            <div class="cart">
                <div class="packages">
                    <package-minimum v-if="getCheckoutPackage.slug == 'minimum'" />
                    <packageBasic v-if="getCheckoutPackage.slug == 'basic'" />
                </div>
            </div>
        </div>

        <footerSection/>
    </section>
</template>
<script>
import packageMinimum from './../../components/youtube-lp/package-minimum';
import packageBasic from './../../components/youtube-lp/package-basic';
import checkoutForm from './../../components/forms/checkout-form';
import cookiesPopup from './../../components/cookies-popup';
import footerSection from "./../../sections/footer-small";
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: mapGetters([
      'getCheckoutPackage'
    ]),
    components: {
        packageMinimum,
        packageBasic,
        checkoutForm,
        cookiesPopup,
        footerSection
    },
    methods: {
        ...mapActions([
            'fetchCheckoutPackage'
        ]),
    },
    async mounted() {
        await this.fetchCheckoutPackage(this.$cookies.get('shopping-cart'));
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.spiner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.error-message {
  span {
    color: #f36;
    font-family: $family-light;
    font-size: 18px;
  }
  .success-note {
    color: green;
    font-family: $family-light;
  }
}

.checkout-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 40px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
}

.logo {
  position: relative;
  top: -3px;
  display: block;
  background-image: url(/images/logo/D&D_h_logo_full_black.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40px;
  width: 94px;
}

.checkout-wrapper {
    background: #F5F6FC;
    min-height: 100vh;

    h2 {
        text-align: center;
        padding: 100px 0 20px;
        @include font-size(40px);
        font-family: $family-bold;
        font-weight: 700;
    }

    h3 {
        padding: 20px 0 0;
        font-weight: 700;
        position: relative;
        bottom: -10px;
    }
}

.checkout-sub-title {
    padding: 0px 0 80px;
    text-align: center;
    font-family: $family-light;
    @include font-size(25px);
}

.checkout-inner {
    width: 80%;
    margin:auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10vh;
}

.fields {
    width: 49.5%;
}

.cart {
    width: 49.5%;
    display: flex;
    justify-content: center;
}

.fields input {
    display: block;
    width: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    resize: none;
    padding: 18px 15px;
    @include font-size(18px);
    color: #8a959e;
    background: #fff;
    font-family: $family-light;
    -webkit-appearance: none;
    appearance: none;
}
.fields-row {
    display: flex;
    padding: 10px 0;
    position: relative;
    z-index: 998;

    input, select {
        display: block;
        width: 100%;
        outline: none;
        padding: 5px;
        border: 1px solid #d0d5d8;
        border-radius: 2px;
        resize: none;
        padding: 18px 15px;
        @include font-size(18px);
        color: #8a959e;
        background: #fff;
        font-family: $family-light;
        -webkit-appearance: none;
        appearance: none;

        &::placeholder {
            color: #8a959e;
        }

        &.error {
            border-color: #f36;
        }
    }
}

.fields-cel {
    z-index: 2;
    width: 49.5%;
    float: left;

    &:first-child {
        margin-right: 1%;
    }
}

.fields-cel-2 {
    width: 100%;
}

.desctop-select {
  position: relative;

  &.error span {
    border-color: #f36;
  }

  span {
    font-family: $family-light;
    position: relative;
    display: block;
    border: 1px solid #d0d5d8;
    border-radius: 2px;
    padding: 18px 15px;
    cursor: pointer;
    color: #8a959e;
    background: #fff;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
  }

  ul {
    display: none;
    position: absolute;
    background: #fff;
    width: 100%;
    border: 1px solid #d0d5d8;
    border-top: 0px;
    z-index: 2;

    li {
      cursor: pointer;
      color: #8a959e;
      @include font-size(18px);
      padding: 18px 15px;
      font-family: $family-light;

      &:hover {
        background: #d0d5d8;
      }
    }
  }

  &.droped {
    ul {
      z-index: 9;
      display: block;
      max-height: 40vh;
      overflow-y: scroll;
    }
  }
}

.preview-wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 220px;
    margin: 10px 0;

    .preview-image {
        position: relative;
        background-position: center;
        background-size: cover;
        width: 50%;
        border-radius: 5px;
        margin-right: 5%;

        &::after {
            content: '';
            position: absolute;
            top: 0%;
            right: 0%;
            bottom: 0%;
            left: 0%;
            background: rgba(0, 0, 0, .7);
            border-radius: 5px;
        }
    }


    .dot-title-video {
        height: 4px;
        width:4px;
        border-radius: 50%;
        background: #909090;
        margin: 10px 7px 0;
        font-family: Helvetica, sans-serif;
    }


    h4 {
        font-family: $family-bold;
        font-size: 22px;
    }

    p {
        font-family: $family-light;
        font-size: 18px;
    }
}

.preview-info-top {
    display: flex;
    position: relative;
    margin-bottom: 5px;


    p {
        font-family: $family-light;
        font-weight: 500;
    }
}

.youtube-title {
    font-weight: 700;
    padding-bottom: 10px;
}

.youtube-text {
    font-family: $family-light;
}

@media screen and (max-width: 1250px) {
    .preview-wrapper {
        display: flex;
        flex-direction: column;
        height: 40vh;

        & + p {
            width: 90%; 
        }
    }
    .preview-wrapper .preview-image {
        height: 40vh;
        border-radius: 5px;
        width: 90%;
        &::after {
            border-radius: 5px;
        }
    }

    .preview-info {
        width: 90%;
        padding-top: 20px;

        .preview-info-top {
            margin-bottom: 15px;
        }

        h4 {
            font-weight: 700;
        }
    }
    
}

.checkbox-label {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.checkbox-label-text {
    padding-left: 5px;
    font-family: $family-light;

    a {
        font-family: $family-light;
        color: #212529;
        text-decoration: underline;
    }
}

.checkbox-wrapper {
    top: 4px;
}

.paypal-button {
    margin-top: 10px;
}

.checkbox-row {
    z-index: 888;
}

.mobile-select {
    position: relative;
    display: none;
    cursor: pointer;
    color: #8a959e;
    @include font-size(18px);

    &::after {
      content: "";
      position: absolute;
      margin-top: -0.6em;
      border: solid transparent;
      border-bottom-color: #a4abb1;
      border-width: 0.3em;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      margin-top: 0.2em;
      border: solid transparent;
      border-width: 0.3em;
      border-top-color: #a4abb1;
      right: 0.8em;
      top: 50%;
      z-index: 1;
    }
}

.eye-icon {
    font-size: 19px;
    display: inline-block;
    background-image: url('/images/icons/eye.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 17px;
    width: 25px;
}

.progress-color {
    color: green;
    font-weight: 500;
    font-family: $family-light;
}

@media (max-width: 1024px) {
    .checkout-inner {
        display: flex;
        flex-direction: column-reverse;
    }

    .cart {
        width: 100%;
        padding-bottom: 10vh;
    }

    .fields {
        width: 100%;
    }

    .desctop-select {
        display: none;
    }

    .mobile-select {
        display: block;
    }
    .preview-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 576px) {

    .checkout-inner {
        width: 90%;
    }

    .checkout-nav {
        padding: 20px 25px;
    }

    .fields-row {
        flex-wrap: wrap;
    }

    .ja.checkout-wrapper h2 {
        padding: 100px 25px 20px;
        @include font-size(30px);
    }

    .ja .checkout-sub-title {
        padding: 0px 20px 80px;
        text-align: center;
        font-family: $family-light;
        @include font-size(19px);
    }

    .fields-row  input, .fields-row select {
        padding: 12px 15px;
        @include font-size(14.5px);
        font-size: 3.5vw;
    }
    
    .fields-cel {
        z-index: 2;
        width: 100%;
        float: left;
        &:first-child {
            margin-right: 0%;
            margin-bottom: 20px;
        }
    }

    .preview-wrapper {
        position: relative;
        overflow: hidden;
        height: auto;
        margin: 10px 0;

        & + p {
            overflow: hidden;
        } 

        .preview-image {
            position: relative;
            height: 200px;
            background-position: center;
            background-size: cover;
            width: 100%;
            border-radius: 5px;

            &::after {
                content: '';
                position: absolute;
                top:0;
                left:0;
                right:0;
                bottom: 0;
                background: rgba(0, 0, 0, .7);
                border-radius: 5px;
            }
        }

        h4 {
            position: static;
            padding-left: 0;
            padding-top: 20px;
            font-family: $family-bold;
            font-size: 22px;
        }

        p {
            position: static;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 0;
            font-family: $family-light;
            font-size: 18px;
        }
    }

    .preview-info {
        width: 100%;
        padding-top: 0;

        h4 {
            padding:0;
        }
    }

    .preview-info-top p{
        padding: 10px 0;
    }
}

.save-form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $gold;
    font-family: $family-bold;
    font-weight: 700;
    
    .save-form-btn-inner {
        text-align: center;
        width: 100%;
        border: 2px solid $gold;
        padding: 20px 0;
        @include font-size(18px);
        @include transition(all 100ms ease);
        
        
        &:hover {
            @include transition(all 100ms ease);
            border: 4px solid $gold;
            padding: 18px 0;
        }
    }

    &.disabled .save-form-btn-inner{
        color: #ccc;
        border: 2px solid #ccc;
    }

    &.disabled .save-form-btn-inner:hover {
        @include transition(all 100ms ease);
        border: 2px solid #ccc;
        padding: 20px 0;
    }

}
</style>