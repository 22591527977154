<template>
    <div v-if="getCheckoutPackage" class="package" :class="{ 'discount': hasDiscount, 'safary': isIos }">
        <img class="thumb-invest-img" src="/images/youtube-promo-long/thumb-invest.png" alt="thumb-invest">
        <h4>{{ getCheckoutPackage.title }}</h4>
        
        <p class="discount-label" v-if="hasDiscount">{{ +discountPrice }}</p>
        <p v-if="hasDiscount" class="discount-price-label"><span class="discount-package-price-label">${{+getCheckoutPackage.price}}</span>{{discountValue}}% OFF!</p>

        <p class="price-label" v-if="+getCheckoutPackage.price">{{ +getCheckoutPackage.price }}</p>
        <p class="price-label" v-if="!+getCheckoutPackage.price">---</p>
        <p>
            <span v-if="$i18n.locale == 'en'">{{ getCheckoutPackage.description }}</span>
            <span v-if="$i18n.locale == 'ja'">{{ getCheckoutPackage.description_ja }}</span>
            <span v-if="$i18n.locale != 'ja'">{{ $t("youtubeLong.packages.offering1.text2") }}</span>
        </p>
        <div class="separatop"></div>
        <ul class="package-params">
            <li>{{ getCheckoutPackage.viewsAmountLocal }} {{ $t("youtubeLong.packages.offering1.list1.option") }}</li>
            <li>{{ $t("youtubeLong.packages.offering1.list2.option1") }}</li>
            <li>{{ $t("youtubeLong.packages.offering1.list2.option2") }}</li>
            <li>{{ $t("youtubeLong.packages.offering1.list2.option3") }}</li>
            <li>{{ $t("youtubeLong.packages.offering1.list2.option4") }}</li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'getCheckoutPackage',
            'hasDiscount',
            'discountPrice',
            'discountValue',
            'isIos'
        ])
    }
}
</script>
<style lang="scss" scoped>
@import "../../scss/app";
@import '../../scss/youtube-lp';

.package {
    position: relative;

    background: #fff;
    padding: 90px 2% 90px;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    @include transition(all .2s ease);

    h4 {
        @extend .small-title;
        padding-bottom: 2vh;
    }

    p {
        span {
            display: block;
            text-align: center;
            @extend .text-font;
        }
    }

    .price-label,
    .discount-price-label,
    .discount-label {
        font-family: $family-bold;
        @include font-size(50px);

        &::before {
            content: '$';
            @include font-size(25px);
            position: relative;
            top: -15px;
        }
    }

    .discount-price-label {
        position: relative;
        &::before {
            content: '$';
            @include font-size(19px);
            position: relative;
            top: -1px;
            left: -8px;
        }
    }


    a {
        margin: 0px 0 50px;
    }

    &.black {
        background: #2F2F2F;
        color: #fff;

        .dnd-btn::before {
            background: #2F2F2F;
        }
    }

    &.begge {
        background: #F7EBE1;
         color: #6E7272;

        .dnd-btn::before {
            background: #F7EBE1;
        }

        .package-params li {
            color: #6E7272;
        }
    }

    &:hover {
        @include translateY(-20px);
    }

    @extend .shadow;
}

.package.discount {
    .price-label {
        display: none;
    }
    .discount-label {
        color: red;
    }
    
    .discount-price-label {
        position: relative;
        display: flex;
        color:rgb(239, 161, 55);
        top: -11px;
        font-family: $family-bold;
        @include font-size(28px);
        line-height: 30px;

        
        &::before {
            display: none;
        }

        .discount-package-price-label {
            position: relative;
            display: flex;
            align-items: flex-end;
            @include font-size(23px);
            font-family: sans-serif;
            font-weight: 600;
            color: #212529;
            margin-right: 6px;
            line-height: 28px;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 15px;
                height: 2px;
                width: 100%;
                background: #212529;
            }
        }
    }
}

.package.discount.safary {
    .discount-price-label {
        .discount-package-price-label {
            top:-2px;
        }
    }
}

.package-params {
    padding-top: 20px;
    width: 100%;
    padding-left: 60px;

    li {
        position: relative;
        padding-bottom: 30px;
        @extend .small-text-font;

        &::before {
            content: '';
            position: absolute;
            left: -46px;
            top: -1px;
            height: 22px;
            width: 22px;
            background-image: url('/images/youtube-promo-long/check-icon.svg');
        }

        &:first-child {
            font-family: $family-bold;
            font-weight: 700;
        }
    }
}



.separatop {
    width: 95%;
    height: 1px;
    background: #ccc;
    margin: 30px 0;
}

.thumb-invest-img {
    position: absolute;
    top: -12%;
    width: 175px;
    margin-left: 50%;
    left:-20px;
    @include translateX(-50%);

    -webkit-box-reflect: below -36% linear-gradient(transparent, transparent, transparent, rgba(255, 255, 255, .7));
}

@media (max-width: 1024px) {
    .packages-wrapper {
        padding-bottom: 5vh;
    }

    .packages {
        padding: 10vh 0 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .package {
        position: relative;
        height: 100%;
        background: #fff;
        width: 50%;
        padding-bottom: 10vh;
        margin-bottom: 10vh;

        &:hover {
            @include translateY(0);
        }
    }
}
@media (max-width: 576px) {

    .packages {
        padding: 10vh 0 5vh;
    }

    .package {
        margin: auto;
        height: 100%;
        width: 90%;
        padding-bottom: 5vh;
        margin-bottom: 10vh;
        padding-top: 5vh;
        min-width: 310px;

        h4 {
            padding-bottom: 0vh;
            padding-top: 2vh;
        }

        .package-params {
            padding-top: 2vh;
        }

        .package-params li {
            padding-bottom: 2vh;
            font-size: 4.3vw;
        }

        a {
            margin: 0px 0 3vh;
        }

        img {
            width: 120px;
        }

        .thumb-invest-img {
            left: -12px;
        }

        .generation-img {
            width: 90px;
        }

        .price-label,
        .discount-price-label,
        .discount-label {
            padding-bottom: 0;
        }
    }

    .separatop {
        width: 95%;
        height: 1px;
        background: #ccc;
        margin-bottom: 10px;
    }

    .packages-wrapper {
        padding: 5vh 0 10vh;
        
        h2 {
            span {
                display: block;
                font-size: 5vw;
            }

            span:first-child {
                color: #939999;
            }

            span:last-child {
                font-size: 7.5vw;
                padding-top: 6px;
            }
        }
    }
}
</style>